* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: VerelaReg;
  font-weight: 400;
}

.smallText {
  font-size: 11px;
}

/* hero */
/*#region */

.heroContainer {
  display: table;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./images/hero.jpg");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.headerContainer {
  width: 100%;
  padding: 2vh 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 99;
}

.headerContainer-scroll {
  width: 100%;
  padding: 2vh 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 99;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
}

.headerLogo {
  width: 30%;
}

.headerLogo-scroll {
  width: 20%;
}

.navLinks a {
  padding: 0 20px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: white;
  letter-spacing: 1px;
}

.navLinks a:hover:before {
  content: "";
  position: absolute;
  top: 20px;
  background: #66b2b2;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  left: calc(51% - 7px);
}

.heroTitle {
  padding: 2vh 2vw;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.heroTitle h1 {
  letter-spacing: 3px;
  margin-bottom: 1vh;
  margin-top: 1vh;
  font-size: 2.5em;
}

.heroTitle h2 {
  letter-spacing: 2px;
}

.heroTitle img {
  width: 40%;
}

.heroBottom {
  padding: 2vh 2vw 0 2vw;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
  display: flex;
  justify-content: space-around;
  width: 1200px;
}

.heroBottom a {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh 3vw 0 3vw;
  border-radius: 25px 25px 0px 0px;
  text-decoration: none;
  color: white;
}

.heroBottom a:hover {
  background: #66b2b2;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.hamburger-menu {
  display: none;
  background: transparent;
  border: none;
}

.hamburger-menu span {
  display: block;
  width: 30px;
  height: 3px;
  margin: 5px auto;
  background-color: #fefefe;
  transition: transform 0.3s ease-in-out;
}

.header-nav-mobile {
  display: none;
}

/* #endregion */

/* seasons */
/* #region */
.seasonContainer {
  width: 1200px;
  padding: 2vh 2vw 0 2vw;
  margin: 2vh auto;
  min-height: 20vh;
  display: flex;
  justify-content: space-around;
}

.seasonContainer div {
  margin: 0 0.5vw;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  width: 35%;
  margin-bottom: 2vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.seasonContainer div img {
  width: 99.9%;
  transform-origin: center;
  max-height: 50%;
}

.seasonContainer div h2 {
  margin: 2vh 0;
  padding: 0 10px;
}

.seasonContainer div p {
  padding: 0 10px;
  margin-bottom: 2vh;
  display: list-item;
  list-style: inside;
}

.seasonImg{
  height: 200px;
  object-fit: cover;
}

/* #endregion */

/* team */
/* #region */
.teamContainer {
  min-height: 40vh;
  padding: 2vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./images/teambanner.jpg");
  background-repeat: no-repeat;
  background-position: center 20%;
  background-size: cover;
  color: white;
}

.classContainer td,
.classContainer th {
  padding: 0.75rem;
  text-align: center;
}
/* #endregion */

/* price */
/* #region */
.priceContainer {
  width: 1200px;
  padding: 2vh 2vw 0 2vw;
  margin: 2vh auto;
  min-height: 20vh;
  display: flex;
  justify-content: space-around;
}

.priceContainer div {
  position: relative;
  border-radius: 16px;
  background: #fff;
  overflow: hidden;
  width: 30%;
  margin-bottom: 2vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.priceContainer div img {
  max-width: 99.9%;
  transform-origin: center;
}

.priceContainer div h2 {
  margin: 2vh 0;
  padding: 0 10px;
}

.priceContainer div p {
  padding: 0 10px;
  margin-bottom: 2vh;
  display: list-item;
  list-style: inside;
}
/* #endregion */

/* program */
/* #region */
.program {
  margin: 2vh auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 30vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./images/teambanner.jpg");
  background-repeat: no-repeat;
  background-position: center 20%;
  background-size: cover;
  padding: 2vh 0;
  color: white;
}

.program h2 {
  margin-bottom: 2vh;
}

.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
}

.tab-list {
  display: flex;
  margin-bottom: 2vh;
}

.tab {
  padding: 5px 10px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  transition: all 0.2s ease-in-out;
  border-radius: 16px;
}

.tab:hover {
  transform: scale(1.05);
}

.indent {
  margin-left: 2vw;
}

.active {
  background: #66b2b2;
}
/* #endregion */

/* About */
/* #region */
.about {
  width: 1200px;
  padding: 2vh 2vw 4vh 2vw;
  border-radius: 16px;
  margin: 3vh auto;
  min-height: 20vh;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.about h2 {
  margin-bottom: 2vh;
}

/* #endregion */

/* Contact */
/* #region */

.contact {
  width: 1200px;
  margin: 3vh auto;
}

.contact h2 {
  text-align: center;
}

.contactContent {
  display: flex;
}

.contactContent1 {
  text-align: center;
  width: 50%;
}

.contactContent1 img {
  width: 50%;
  border-radius: 16px;
}

.contactContent2 {
  width: 50%;
}
.contactContent2 p {
  margin: 2vh 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.contactContent2 p img {
  margin-right: 1vw;
}

.contactContent2 p a {
  text-decoration: none;
  color: #66b2b2;
}

.contactContent2 p a:hover {
  text-decoration: 2px underline #66b2b2;
}

/* #endregion */

/* Footer */
/* #region */
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 2vw;
}

footer img {
  width: 50px;
}
/* #endregion */

/************** animated arrows ***************/
/* #region */
.arrows {
  width: 60px;
  height: 72px;
  transform: scale(0.4);
}

.arrows path {
  stroke: white;
  fill: transparent;
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}
/* #endregion */

/* Media Query */
/* #region */
@media only screen and (max-width: 1200px) {
  .heroBottom {
    width: 100%;
  }
  .seasonContainer {
    width: 100%;
  }
  .seasonContainer div {
    width: 40%;
  }
  .priceContainer {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .priceContainer div {
    width: 80%;
  }
  .tabs {
    width: 80%;
  }

  .about {
    width: 80%;
  }

  .contact {
    width: 80%;
  }
  .contactContent {
    flex-direction: column;
    align-items: center;
  }
  .contactContent div {
    width: 80%;
  }
}

@media only screen and (max-width: 900px) {
  .navLinks {
    display: none;
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
    width: 33%;
  }

  .header-nav-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
    margin-top: 5vh;
  }
  .header-nav-mobile a {
    padding: 10px 20px;
    text-decoration: none;
    color: white;
    letter-spacing: 1px;
  }

  .mobileHeader {
    background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
    justify-content: space-between;
    align-items: flex-start;
    padding: 2vh 2vw;
    width: 100vw;
    height: 100vh;
  }
  .imgContainer {
    width: 33%;
  }
}

@media only screen and (max-width: 600px) {
  .seasonContainer {
    width: 80%;
    flex-direction: column;
  }

  .seasonContainer div {
    width: 100%;
  }

  .contactContent div {
    width: 100%;
  }

  footer {
    width: 80%;
    margin: auto;
    flex-direction: column-reverse;
  }

  footer p {
    font-size: 15px;
  }

  footer img {
    width: 30px;
  }
}

/* #endregion */
